<template>
  <div>
    <About :aboutInfo="aboutInfo" :titleMoved="titleMoved"/>
    <AboutDescription />
  </div>
</template>

<script>
import About from "@/components/About";
import AboutDescription from "@/components/AboutDescription";

export default {
  name: "AboutPage",
  components: {AboutDescription, About},
  data() {
    return {
      aboutInfo: {
        title: "Why Scartesu Limited?",
      },
      titleMoved: "Title--position",
    }
  }
}
</script>

<style scoped lang="scss">

</style>