<template>
  <div class="AboutDescription">
    <div class="Container">
      <div class="AboutDescription-List">
        <div class="AboutDescription-Item"
             v-for="text in info"
             :key="text.id"
             :class="text.position"
        >
          <p class="AboutDescription-ItemText" v-html="text.text"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutDescription",
  data() {
    return {
      info: [
        {
          text: "Our team has more than <span class='Strong'>150 first-class professionals</span> in their field",
          position: 'AboutDescription-Item--positionL'
        },
        {
          text: "<span class='Strong'>Dozens of satisfied customers</span> have developed their businesses thanks to mobile applications",
          position: 'AboutDescription-Item--positionR'
        },
        {
          text: "<span class='Strong'>2 years of expertise</span> allow us to deeply immerse ourselves in every project, to develop and implement complex strategy for <span class='Strong'>attracting new paying users.</span>",
          position: 'AboutDescription-Item--positionL'
        },
        {
          text: "Experts team and the own ASOmaker.com analytical platform help us to increase volume of organic users <span class='Strong'>with a guarantee.</span>",
          position: 'AboutDescription-Item--positionR'
        },
        {
          text: "We have 30 UA managers in-house and our own optimization technology for getting <span class='Strong'>high quality</span> users and ROI",
          position: 'AboutDescription-Item--positionL'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.AboutDescription {
  padding: 20px 0 77px;

  &-List {
    @media (min-width: $screen-l) {
      padding-left: 160px;
    }
    @media (min-width: $screen-xl) {
      padding-left: 280px;
    }
  }

  &-Item {
    width: 100%;
    //height: 140px;
    padding: 80px 15px 20px;
    margin-bottom: 55px;
    background-color: var(--color-text-main3);
    box-shadow: var(--color-shadow);
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 1.69em;

    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 66px;
      height: 76px;
      top: 0;
      background-image: url("../assets/images/about-check.svg");
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: $screen-l) {
        width: 156px;
        height: 176px;
        top: -20px;
      }

      @media (min-width: $screen-xl) {
        width: 196px;
        height: 226px;
        top: -42px;
      }
    }

    @media (min-width: $screen-l) {
      width: 650px;
      height: 140px;
      padding: 0;
      font-size: 22px;
      line-height: 1.8em;
    }
  }

  &-Item--positionR {
    &:before {
      left: 50%;
      transform: translate(-50%, 0);
    }

    @media (min-width: $screen-l) {
      padding: 5px 71px 5px 30px;
      &:before {
        left: 95%;
        //right: -145px;
        transform: translate(0, 0);
      }
    }

    @media (min-width: $screen-xl) {
      margin-left: 120px;
    }
  }

  &-Item--positionL {
    &:before {
      left: 50%;
      transform: translate(-50%, 0);
    }
    @media (min-width: $screen-l) {
      padding: 5px 30px 5px 71px;
      &:before {
        left: -125px;
        transform: translate(0, 0);
      }
    }

    @media (min-width: $screen-xl) {
      padding: 5px 30px 5px 71px;
      &:before {
        left: -145px;
        transform: translate(0, 0);
      }
    }
  }
}

</style>