<template>
  <section class="About">
    <div class="Container">
      <h1 :class="titleMoved"
          class="Title About-Title"
          v-html="aboutInfo.title"
      ></h1>
      <p class="Text About-Text"
        :class="textWidth"
      >
        {{ aboutInfo.text }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
  props: {
    aboutInfo: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    titleMoved: {
      type: String,
      required: false,
      default: ""
    },
    textWidth: {
      type: String,
      required: false,
      default: ""
    }
  }
}
</script>

<style scoped lang="scss">
.About {
  padding: 48px 0 68px;

  &-Title {
    margin-bottom: 20px;

    &:before {
      left: 0;

      @media (min-width: $screen-l) {
        left: -995px;
      }
    }

    @media (min-width: $screen-l) {
      margin-bottom: 50px;
    }
  }

  &-Text {
    font-size: 25px;
    @media (min-width: $screen-l) {
      width: 862px;
      font-size: 40px;
    }
  }
}

.Title--moved {
  @media (min-width: $screen-l) {
    &:before {
      left: -670px;
    }
  }
}

.Text--width {
  width: 100%;
}

.Title--position {
  @media (min-width: $screen-l) {
    &:before {
      left: -480px;
    }
  }
}
</style>